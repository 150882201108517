<script>
export default {
  data() {
    return {};
  },
};
</script>

<template>
  <!-- Pricing start -->
  <section class="section" id="pricing">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold">Web Development Pricing</h2>
          <p class="text-muted">
            What type of site is right for your business today?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-4 pricing-tab">
                <div class="row">
                  <div class="col-lg-4">
                    <div
                      class="card plan-card mt-4 rounded text-center border-0 shadow overflow-hidden"
                    >
                      <div class="card-body px-4 py-5">
                        <span
                          class="badge badge-primary pricing-badge shadow-lg"
                          >Most Popular</span
                        >

                        <div
                          class="icon-mono avatar-md bg-soft-primary rounded mx-auto mb-5 p-3"
                        >
                          <img
                            src="@/assets/images/pricing/1.png"
                            alt=""
                            class="img-fluid d-block mx-auto"
                          />
                        </div>
                        <h4 class="text-uppercase mb-4 pb-1">Starter Site</h4>
                        <p class="text-muted">
                          "Brochure" site
                        </p>
                        <p class="text-muted">
                          Fast, single page app
                        </p>
                        <p class="text-muted">
                          No maintenance needed
                        </p>
                        <p class="text-muted mb-4 pb-1">
                          Quarterly updates included
                        </p>
                        <p class="text-muted font-size-14 mb-1">
                          Start today for just
                        </p>
                        <p class="text-dark font-size-16 font-weight-semibold">
                          $500
                        </p>
                        <p class="text-muted font-size-12 mb-4 pb-1">
                          Includes domain name, email, and hosting for a year ($220 value)
                        </p>
                        <a v-scroll-to="'#contact'" href="javascript: void(0);" class="btn btn-primary">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="card plan-card mt-4 rounded text-center border-0 shadow overflow-hidden"
                    >
                      <div class="card-body px-4 py-5">
                        <div
                          class="icon-mono avatar-md bg-soft-primary rounded mx-auto mb-5 p-3"
                        >
                          <img
                            src="@/assets/images/pricing/2.png"
                            alt=""
                            class="img-fluid d-block mx-auto"
                          />
                        </div>
                        <h4 class="text-uppercase mb-4 pb-1">Content & Commerce</h4>
                        <p class="text-muted">
                          WordPress based site
                        </p>
                        <p class="text-muted">
                          Includes blog and ecommerce
                        </p>
                        <p class="text-muted">
                          Maintenance contracts available
                        </p>
                        <p class="text-muted mb-4 pb-1">
                          Unlimited self-serve content updates
                        </p>
                        <p class="text-muted font-size-14 mb-1">
                          Starts as low as
                        </p>
                        <p class="text-dark font-size-16 font-weight-semibold">
                          $1,500
                        </p>
                        <p class="text-muted font-size-12 mb-4 pb-1">
                          Includes domain name, email, and hosting for a year ($220 value)
                        </p>
                        <a v-scroll-to="'#contact'" href="javascript: void(0);" class="btn btn-soft-primary">
                          Find Out More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="card plan-card mt-4 rounded text-center border-0 shadow overflow-hidden"
                    >
                      <div class="card-body px-4 py-5">
                        <div
                          class="icon-mono avatar-md bg-soft-primary rounded mx-auto mb-5 p-3"
                        >
                          <img
                            src="@/assets/images/pricing/3.png"
                            alt=""
                            class="img-fluid d-block mx-auto"
                          />
                        </div>
                        <h4 class="text-uppercase mb-4 pb-1">Custom App</h4>
                        <p class="text-muted">
                          More complex sites
                        </p>
                        <p class="text-muted">
                          SaaS, web, or mobile app
                        </p>
                        <p class="text-muted">
                          Service contract included
                        </p>
                        <p class="text-muted mb-4 pb-1">
                          Custom platform development
                        </p>
                        <p class="text-muted font-size-14 mb-1">
                          Projects start at
                        </p>
                        <p class="text-dark font-size-16 font-weight-semibold">
                          $10,000
                        </p>
                        <p class="text-muted font-size-12 mb-4 pb-1">
                          Custom projects start with a lower-cost discovery phase to clarify your requirements.
                        </p>
                        <a v-scroll-to="'#contact'" href="javascript: void(0);" class="btn btn-soft-primary">
                          Request a Quote
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end row-->
          </div>
          <!-- end tab content -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Pricing end -->
</template>
