<script>
import { ArrowRightIcon } from "vue-feather-icons";

export default {
  components: { ArrowRightIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- Features start -->
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">Featured Services</h2>
            <p class="text-muted">
              ...
            </p>
          </div>
        </div>

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5">
            <h2 class="mb-4">Web Development Services</h2>
            <p class="text-muted mb-5">
              Our experienced developers can help with your technology needs. Whether that is establishing your online presence with a starter site or growing your ecommerce presence, we can build a custom website for your business that's optimized for you and your users. User experience, search engine rankings, we can address it all. We offer a range of services, including website design, development, hosting, and maintenance to help you establish a strong online presence and attract more customers to your business.
            </p>
            <router-link to="/web" class="btn btn-primary">
              Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon>
            </router-link>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img
                  src="@/assets/images/features-1.jpg"
                  alt=""
                  class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                src="@/assets/images/dot-img.png"
                alt=""
                class="dot-img-left"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
<!--
        <div class="row align-items-center section pb-0">
          <div class="col-md-6">
            <div class="position-relative mb-md-0 mb-5">
              <div class="me-5 features-img">
                <img
                  src="@/assets/images/features-2.jpg"
                  alt=""
                  class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                src="@/assets/images/dot-img.png"
                alt=""
                class="dot-img-right"
              />
            </div>
          </div>
          <div class="col-md-5 ms-md-auto">
            <h2 class="mb-4">
              Build community & conversion with our suite of social tool
            </h2>
            <p class="text-muted mb-5">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium totam rem aperiam eaque ipsa
              quae ab illo inventore veritatis..
            </p>
            <a href="javascript: void(0);" class="btn btn-primary"
              >Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon
            ></a>
          </div>
        </div>
-->
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Features end -->

    <section class="section bg-gradient-primary">
      <div
        class="bg-overlay-img"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/demos.png') + ')',
        }"
      ></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="text-center">
              <h1 class="text-white mb-4">Let us build your dream platform today</h1>
              <p class="text-white mb-5 font-size-16">
For more complex projects, we offer custom development services, including full-blown software-as-a-service (SaaS) platforms, web apps, browser extension, and mobile apps (Android and/or iOS). These projects are ideal for businesses that need more advanced functionality, such as cryptocurrency/blockchain interactions, AI/chat interfaces, or interfacing with other systems. Our team of experts will work with you to understand your unique needs and create a custom solution that meets your business goals.
              </p>
              <router-link to="/web#pricing" class="btn btn-lg btn-light">
                Explore the Possibilities
              </router-link>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Cta end -->
  </div>
</template>
