<script>
import Navbar from "@/components/navbar";
import Services from "@/components/services";
import Features from "@/components/features";
import Contact from "@/components/contact";
import Footer from "@/components/footer";

export default {
  components: {
    Navbar,
    Services,
    Features,
    Contact,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <Navbar :navcolor="'light'" />
    <div v-scroll-spy>
      <!-- Hero Start -->

      <!-- Hero Start -->
      <section
        class="hero-2 position-relative overflow-hidden"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/hero-2-bg.png') + ')',
        }"
        id="home"
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7">
              <span class="badge badge-soft-primary mb-4">Professional Services</span>
              <h2 class="font-weight-semibold mb-4">
52 Wall Street:
              </h2>
              <h1 class="font-weight-semibold mb-4 hero-2-title">
Business services for Benton Harbor entrepreneurs
              </h1>
              <p>
Are you tired of working from your back bedroom or meeting clients in a coffee shop? Do you want to establish credibility and professionalism for your business without breaking the bank?
              </p>
              <p class="font-weight-semibold font-size-18">
                Welcome to Wall Street!
              </p>
              <p>
Our business address and mail services allow you to register your business entity to a professional Wall Street address, giving you the credibility you need to impress potential clients and partners. And with our co-working and office leasing options, you can have access to a dedicated workspace that grows with your business needs, without committing to thousands of square feet of space you don't need.
              </p>
              <p>
And you need a web presence as well as a physical presence. Our experienced developers can build a website for your business that's optimized for your needs. We offer a range of services, including website design, development, hosting, and maintenance -- all to help you establish a strong online presence and attract more customers to your business.
              </p>
              <p>
In addition to our premium business services, we also offer a wide range of educational content and resources to help you overcome common challenges in growing your business. From business seminars to themed "work-a-thons" to networking events, we've got you covered!
              </p>
              <p>
Ready to take your business to the next level? Contact us today to learn more about our services and find out how we can help you achieve your business goals.
              </p>
            </div>
            <div class="col-lg-5 col-sm-10 mx-auto ms-lg-auto me-lg-0">
              <div class="mt-lg-0 mt-4">
                <img
                  src="@/assets/images/hero-2-img.png"
                  alt=""
                  class="img-xl-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Hero End -->
      <Services />
      <Features />
      <Contact />
      <Footer />
    </div>
  </div>
</template>
