<script>
import {
  MailIcon,
  PhoneIcon,
  MapPinIcon
} from "vue-feather-icons";

export default {
  components: { MailIcon, PhoneIcon, MapPinIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <!-- Contact us start -->
  <section class="section" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="fw-bold mb-4">Learn More </h2>
          <p class="text-muted mb-5">
            Reach out and let's start a conversation about how we can help your business. Whether that's web space or office space, web address or business address -- we're happy to hear your needs.
          </p>

          <div>
            <form action="https://formsubmit.co/db43262f63b8cd231242e3a249b6dfec" method="post" name="myForm">
              <input type="hidden" name="_next" value="https://52WallSt.com/thanks">
              <input type="hidden" name="_subject" value="52WallSt.com Form Submission">
              <p id="error-msg"></p>
              <div id="simple-msg"></div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-4">
                    <label for="name" class="text-muted form-label">Name</label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      class="form-control"
                      placeholder="Enter name*"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-4">
                    <label for="email" class="text-muted form-label"
                      >Email</label
                    >
                    <input
                      name="email"
                      id="email"
                      type="email"
                      class="form-control"
                      placeholder="Enter email*"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-4">
                    <label for="subject" class="text-muted form-label"
                      >Subject</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Enter Subject.."
                    />
                  </div>

                  <div class="mb-4 pb-2">
                    <label for="comments" class="text-muted form-label"
                      >What is your situation and how might we help?</label
                    >
                    <textarea
                      name="comments"
                      id="comments"
                      rows="4"
                      class="form-control"
                      placeholder="Enter message..."
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    class="btn btn-primary"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-5 ms-lg-auto">
          <div class="mt-5 mt-lg-0">
            <img
              src="@/assets/images/contact.png"
              alt=""
              class="img-fluid d-block"
            />
            <p class="text-muted mt-5 mb-3">
              <mail-icon class="me-2 text-muted icon icon-xs"></mail-icon>
              services@52WallSt.com
            </p>
            <p class="text-muted mb-3">
              <phone-icon class="me-2 text-muted icon icon-xs"></phone-icon>
              Txt/vm 269-605-4408
            </p>
            <p class="text-muted mb-3">
              <map-pin-icon
                class="me-2 text-muted icon icon-xs"
              ></map-pin-icon>
              52 W Wall St, Benton Harbor, MI 49022
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Contact us end -->
</template>
