import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/index.vue')
    },
    {
        path: '/web',
        component: () => import('../views/web.vue')
    },
    {
        path: '/thanks',
        component: () => import('../views/thanks.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to) {
      if (to.hash) {
        return { selector: to.hash, }
      }
      return { x: 0, y: 0, }
    },
    routes
})

export default router
