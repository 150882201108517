<script>
import ThanksNavbar from "@/components/thanksnavbar";
import Footer from "@/components/footer";

export default {
  components: {
    ThanksNavbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <ThanksNavbar :navcolor="'light'" />
    <div v-scroll-spy>
      <!-- Hero Start -->

      <!-- Hero Start -->
      <section
        class="hero-2 position-relative overflow-hidden"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/hero-2-bg.png') + ')',
        }"
        id="home"
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <h1 class="font-weight-semibold mb-4 hero-2-title">
And it is off! (Aren't we lucky?!)
              </h1>
              <p>
Your message is on its way. Hopefully the carrier pigeon will make it.
              </p>
              <p>
We'll get back to you as soon as possible. If it is urgent, feel free to text us at 269-605-4408.
              </p>
              <p>
Thanks!
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- Hero End -->
      <Footer />
    </div>
  </div>
</template>
