<script>
import WebNavbar from "@/components/webnavbar";
import WebPricing from "@/components/webpricing";
import Contact from "@/components/contact";
import Footer from "@/components/footer";

export default {
  components: {
    WebNavbar,
    WebPricing,
    Contact,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <WebNavbar :navcolor="'light'" />
    <div v-scroll-spy>
      <!-- Hero Start -->

      <!-- Hero Start -->
      <section
        class="hero-2 position-relative overflow-hidden"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/hero-2-bg.png') + ')',
        }"
        id="home"
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 font-size-17">
              <h2 class="font-weight-semibold mb-4">
Establishing Your Digital Presence
              </h2>
              <h1 class="font-weight-semibold mb-4 hero-2-title">
Why Your Business Needs a Website
              </h1>
              <p>
In today's digital age, having a website for your business is no longer an option, but a necessity. A website is like a virtual storefront that is open 24/7, and it can be the first impression potential customers have of your business. Not having a website means you are potentially missing out on a huge audience of customers who are searching for businesses like yours online.
              </p>
              <p>
One of the first things you'll want to do when establishing a website for your business is to create a professional email address. An email address that ends in yahoo or gmail may seem convenient, but it can also make your business look unprofessional. By using a professional email address that includes your domain name, you'll be able to build trust with potential customers and partners.
              </p>
              <p>
Once you have your website up and running, there are a few key concerns you'll want to keep in mind. One of the most important considerations is site speed. Slow-loading pages can frustrate visitors and cause them to leave your site before even seeing what you have to offer. Therefore, it's crucial to optimize your website's speed to ensure a smooth user experience.
              </p>
              <p>
Another crucial consideration is website security. Cybersecurity threats are on the rise, and without proper security measures in place, your website and customer data could be at risk. Regularly updating your website's software, using strong passwords, and implementing SSL encryption are all important steps you can take to keep your site and customer data secure.
              </p>
              <p>
Your website needs to be more than just a digital flyer. Your customers will be accessing your site on a variety of devices, from desktop computers to mobile phones, so it's important that your site is mobile-responsive and looks great no matter how your customers are accessing it. A professional, mobile-responsive website can also help with search engine optimization (SEO) by improving your search engine rankings and making it easier for potential customers to find you. Additionally, a website that's easy to use and provides a great user experience can help establish trust and credibility with your customers, ultimately leading to more conversions and business growth.
              </p>
              <p>
Having a website for your business is no longer optional. It's a necessity in today's digital age. By establishing your digital presence with a professional website, you'll be able to build trust with potential customers, increase your visibility online, and reach a wider audience. With proper attention to site speed, security, and other important considerations, you can create a website that helps your business thrive.
              </p>
            </div>
          </div>
        </div>
      <WebPricing />
      </section>
      <!-- Hero End -->
      <Contact />
      <Footer />
    </div>
  </div>
</template>
