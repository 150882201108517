<script>
import { BoxIcon, LayersIcon, ServerIcon } from "vue-feather-icons";

export default {
  components: { BoxIcon, LayersIcon, ServerIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <!-- Services start -->
  <section class="section" id="services">
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold">Our Services</h2>
          <p class="text-muted">
            Ready to take your business to the next level? Contact us today to learn more about our services and find out how we can help you achieve your business goals.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <box-icon class=""></box-icon>
              </div>
              <h4 class="mb-3 font-size-22">Co-Working Membership</h4>
              <p class="text-muted mb-0">
                Get access to a hot desk and high-speed internet with flexible month-to-month membership options.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <box-icon class=""></box-icon>
              </div>
              <h4 class="mb-3 font-size-22">Conference Rooms</h4>
              <p class="text-muted mb-0">
                Professional meeting spaces available to collaborate with clients and partners.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div
            class="service-box text-center px-4 py-5 position-relative mb-4"
          >
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <layers-icon class=""></layers-icon>
              </div>
              <h4 class="mb-3 font-size-22">Office Leasing</h4>
              <p class="text-muted mb-0">
                Flexible length leases available for single offices or suites as your business grows.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <server-icon class=""></server-icon>
              </div>
              <h4 class="mb-3 font-size-22">Business Address</h4>
              <p class="text-muted mb-0">
                Establish your business with a Wall Street address and professional mail handling services.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div
            class="service-box text-center px-4 py-5 position-relative mb-4"
          >
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <layers-icon class=""></layers-icon>
              </div>
              <h4 class="mb-3 font-size-22">Professional Development</h4>
              <p class="text-muted mb-0">
                Business seminars, networking events, and resources to level up in your business.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-4">
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <server-icon class=""></server-icon>
              </div>
              <h4 class="mb-3 font-size-22">Live Events</h4>
              <p class="text-muted mb-0">
                Host your own events in our attached event venue to raise your community profile.
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Services end -->
</template>
